Error Page
.error .clip .shadow {
    height: 180px;
    /*Contrall*/
}

.error .clip:nth-of-type(2) .shadow {
    width: 130px;
    /*Contrall play with javascript*/
}

.error .clip:nth-of-type(1) .shadow,
.error .clip:nth-of-type(3) .shadow {
    width: 250px;
    /*Contrall*/
}

.error .digit {
    width: 150px;
    /*Contrall*/
    height: 150px;
    /*Contrall*/
    line-height: 150px;
    /*Contrall*/
    font-size: 120px;
    font-weight: bold;
}

.error h2

/*Contrall*/
    {
    font-size: 32px;
}

.error .msg

/*Contrall*/
    {
    top: -190px;
    left: 30%;
    width: 80px;
    height: 80px;
    line-height: 80px;
    font-size: 32px;
}

.error span.triangle

/*Contrall*/
    {
    top: 70%;
    right: 0%;
    border-left: 20px solid #535353;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
}


.error .container-error-404 {
    margin-top: 10%;
    position: relative;
    height: 250px;
    padding-top: 40px;
}

.error .container-error-404 .clip {
    display: inline-block;
    transform: skew(-45deg);
}

.error .clip .shadow {

    overflow: hidden;
}

.error .clip:nth-of-type(2) .shadow {
    overflow: hidden;
    position: relative;
    box-shadow: inset 20px 0px 20px -15px rgba(150, 150, 150, 0.8), 20px 0px 20px -15px rgba(150, 150, 150, 0.8);
}

.error .clip:nth-of-type(3) .shadow:after,
.error .clip:nth-of-type(1) .shadow:after {
    content: "";
    position: absolute;
    right: -8px;
    bottom: 0px;
    z-index: 9999;
    height: 100%;
    width: 10px;
    background: linear-gradient(90deg, transparent, rgba(173, 173, 173, 0.8), transparent);
    border-radius: 50%;
}

.error .clip:nth-of-type(3) .shadow:after {
    left: -8px;
}

.error .digit {
    position: relative;
    top: 8%;
    color: white;
    background: #07B3F9;
    border-radius: 50%;
    display: inline-block;
    transform: skew(45deg);
}

.error .clip:nth-of-type(2) .digit {
    left: -10%;
}

.error .clip:nth-of-type(1) .digit {
    right: -20%;
}

.error .clip:nth-of-type(3) .digit {
    left: -20%;
}

.error h2 {
    color: #A2A2A2;
    font-weight: bold;
    padding-bottom: 20px;
}

.error .msg {
    position: relative;
    z-index: 9999;
    display: block;
    background: #535353;
    color: #A2A2A2;
    border-radius: 50%;
    font-style: italic;
}

.error .triangle {
    position: absolute;
    z-index: 999;
    transform: rotate(45deg);
    content: "";
    width: 0;
    height: 0;
}

/* Error Page */
@media(max-width: 767px) {

    /* Error Page */
    .error .clip .shadow {
        height: 100px;
        /*Contrall*/
    }

    .error .clip:nth-of-type(2) .shadow {
        width: 80px;
        /*Contrall play with javascript*/
    }

    .error .clip:nth-of-type(1) .shadow,
    .error .clip:nth-of-type(3) .shadow {
        width: 100px;
        /*Contrall*/
    }

    .error .digit {
        width: 80px;
        /*Contrall*/
        height: 80px;
        /*Contrall*/
        line-height: 80px;
        /*Contrall*/
        font-size: 52px;
    }

    .error h2

    /*Contrall*/
        {
        font-size: 24px;
    }

    .error .msg

    /*Contrall*/
        {
        top: -110px;
        left: 15%;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
    }

    .error span.triangle

    /*Contrall*/
        {
        top: 70%;
        right: -3%;
        border-left: 10px solid #535353;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
    }

    .error .container-error-404 {
        height: 150px;
    }

    /* Error Page */
}

/*--------------------------------------------Framework --------------------------------*/

.overlay {
    position: relative;
    z-index: 20;
}

/*done*/
.ground-color {
    background: white;
}

/*done*/
.item-bg-color {
    background: #EAEAEA
}

/*done*/

/* Padding Section*/
.padding-top {
    padding-top: 10px;
}

/*done*/
.padding-bottom {
    padding-bottom: 10px;
}

/*done*/
.padding-vertical {
    padding-top: 10px;
    padding-bottom: 10px;
}

.padding-horizontal {
    padding-left: 10px;
    padding-right: 10px;
}

.padding-all {
    padding: 10px;
}

/*done*/

.no-padding-left {
    padding-left: 0px;
}

/*done*/
.no-padding-right {
    padding-right: 0px;
}

/*done*/
.no-vertical-padding {
    padding-top: 0px;
    padding-bottom: 0px;
}

.no-horizontal-padding {
    padding-left: 0px;
    padding-right: 0px;
}

.no-padding {
    padding: 0px;
}

/*done*/
/* Padding Section*/

/* Margin section */
.margin-top {
    margin-top: 10px;
}

/*done*/
.margin-bottom {
    margin-bottom: 10px;
}

/*done*/
.margin-right {
    margin-right: 10px;
}

/*done*/
.margin-left {
    margin-left: 10px;
}

/*done*/
.margin-horizontal {
    margin-left: 10px;
    margin-right: 10px;
}

/*done*/
.margin-vertical {
    margin-top: 10px;
    margin-bottom: 10px;
}

/*done*/
.margin-all {
    margin: 10px;
}

/*done*/
.no-margin {
    margin: 0px;
}

/*done*/

.no-vertical-margin {
    margin-top: 0px;
    margin-bottom: 0px;
}

.no-horizontal-margin {
    margin-left: 0px;
    margin-right: 0px;
}

.inside-col-shrink {
    margin: 0px 20px;
}

/*done - For the inside sections that has also Title section*/
/* Margin section */

hr {
    margin: 0px;
    padding: 0px;
    border-top: 1px dashed #999;
}

/* no edit */
#center {
    margin: auto;
    width: 100%;
    padding: 10px;
}

#NoEdit {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#text {
    text-align: center;
    font-size: 42px;
    color: #636b6f;
}

/* edit booking */
#bk-bg1 {
    background-color: #cb2734;
    padding: 50px;
}

#bk-bg2 {
    background-image: url(../assets/image/book_bg.jpeg);
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
    background-position: center;
}

#booking-details input {
    border-radius: 15px;
}

.book_sum {
    background-color: #fa8426;
    color: #cb2734;
    border-radius: 30px;
}

#book_confirm {
    background-color: #fa8426;
    color: #cb2734;
    border-radius: 30px;
}

#booking-details select {
    border-radius: 15px;
}

#booking-details h3 {
    color: white;
}

#booking-details label {
    color: white;
}

#booking-details h4 {
    color: white;
}

#booking-details span {
    color: white;
}

.bk-hover {
    background-color: #fa8426 !important;
    border-radius: 15px;
}

.bk-hover span {
    color: black !important;
}

.bk-hover .travel_img {
    filter: invert(0%) sepia(98%) saturate(13%) hue-rotate(6deg) brightness(99%) contrast(100%);
}

.travel_img {
    width: 50%;
    height: 50%;
    filter: brightness(0) invert(1);
}

.textSpan {
    color: white;
}

.bk-si {
    background-color: white;
    border: 2px solid #cb2734;
    border-radius: 30px;
    padding: 20px;
}

.side-color {
    color: #cb2734 !important;
}

.side-img-col {
    filter: invert(50%) sepia(23%) saturate(808%) hue-rotate(299deg) brightness(95%) contrast(93%);
}

.check_data {
    border-right: 2px solid #cb2734;
}

.icon_span {
    padding-left: 10px;
}

#ch_bk {
    color: white !important;
}

#edit-ico {
    position: relative;
    top: -40px;
}

#sm-size {
    color: #cb2734 !important;
}

@media screen and (max-width: 425px) {
    #sm-size {
        text-align: left !important;
        color: #cb2734 !important;
    }

    #bk-bg1 {
        padding: 5px;
    }

    .bk-hover {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

.book-now-btn {
    margin-top: 20px;
    border: none;
    background-color: #ff8901;
    color: black;
    border-radius: 1.8em !important;
    padding: 10px 50px;
    font-size: 16px;
    font-weight: bold;
}

#add_stop {
    background-color: #ff8901 !important;
    color: black !important;
    border-radius: 20px !important;
    padding: 5px 20px 5px 20px !important;
    font-weight: bold !important;
}

.add_stop {
    background-color: #ff8901 !important;
    color: black !important;
    border-radius: 20px !important;
    padding: 5px 20px 5px 20px !important;
    font-weight: bold !important;
}

.bk-row {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.site-footer {
    content: "" !important;
    position: absolute !important;
    width: 100%;
    background-color: #cb2734;
    font-size: 18px;
}

.site-footer p {
    color: white !important;
}

.site-footer a {
    color: white !important;
    text-decoration: none;
}

/* time css change */
.rdtCounters>div {
    color: #000000 !important;
}

.rdtCounters {
    display: block !important;
}

.rdtPicker {
    min-width: 0 !important;
}

#chck-flight-dtls{
    background-color: #f8982e;
    color: black;
    border-radius: 28px;
    font-weight: bold
}

.number_paddE{
    color: #cb2734 !important
}

.rdtCounters>div {
    color: #000000 !important;
}

.rdtBtn{
    color: #000000 !important;
}

.rdtCounters {
    display: block !important;
}

.rdtPicker {
    min-width: 0 !important;
}