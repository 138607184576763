.sec-title {
    text-align: center;
}

.sec-title h1 {
    margin-top: 20px;
    color: #610606;
    font-size: 45px;
    font-weight: bold;
}

.sec-title img {
    width: 190px;
}

.login-form h2 {
    color: white;
    text-align: center;
    font-weight: bold;
}

.login-form p {
    color: rgb(192, 186, 186);
    text-align: center;
}

.enter-phone {
    margin-top: 40px;
}

.enter-phone label {
    color: whitesmoke;
}

.enter-phone input {
    border-radius: 1em;
}

.enter-phone input:focus {
    border-color: none;
    box-shadow: none;
}

.login-form {
    margin-top: 25px;
    border-radius: 1em;
    background-color: #c82f30;
    padding: 45px 20px;
}

.login-form h2 {
    color: white;
    text-align: center;
    font-weight: bold;
}

.login-form p {
    color: rgb(192, 186, 186);
    text-align: center;
}

.proceed-btn {
    margin-top: 20px;
    border: none;
    background-color: #ff8901;
    color: white;
    border-radius: 1.8em;
    padding: 10px 50px;
    font-size: 16px;
    font-weight: bold;
}

.verify-section {
    background-image: url(../assets/image/Verification_BG.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
}