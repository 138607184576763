:root {
    --bgcolor: #ca2733;
}

.mapBg {
    height: 100% !important;
    width: 100% !important;
    left: 0;
    position: absolute;
    top: 0;
    z-index: -1;
}

.textColor{
    color: var(--bgcolor);
}

.divBg {
    background-color: var(--bgcolor);
    color: #fff;
    padding: 25px !important;
    border-radius: 10px;
    height: 750px;
    min-height: 750px;
    overflow-y: scroll;
}

.modal-backdrop {
    background-color: transparent !important;
}

/* hide div */
.hideDiv {
    background-color: var(--bgcolor);
    color: #fff;
    padding: 25px !important;
    border-radius: 10px;
}

@media screen and (min-width:300px) and (max-width: 340px){
    .modelHeight {
        width: 300px !important;
    }
}
@media screen   and (min-width:350px) and (max-width: 375px){
    .modelHeight {
        width: 340px !important;
    }
}



@media screen and (min-width:320px) and (max-width:480px) {
    .divBg {
        height: 475px !important;
        min-height: 475px !important;
    }

    .modal.left .modal-dialog {
        right: 0px !important;
        margin-top: 18% !important;
    }

    .arrowIcon {
        position: absolute !important;
        top: 650px !important;
        bottom: 0px !important;
    }

    .fare-total{
        font-size: 12px !important;
    }

    .fare-total span {
        font-size: 12px !important;
    }

    .option-vehimage{
        width: 9% !important;
        height: 9% !important;
    }

    .number_padd{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

@media screen and (min-width:481px) and (max-width:768px) {
    .divBg {
        height: 475px !important;
        min-height: 475px !important;
    }

    .modal.left .modal-dialog {
        right: 5px !important;
        margin-top: 12% !important;
    }

    .arrowIcon {
        position: absolute !important;
        top: 650px !important;
        bottom: 0px !important;
    }
}

@media screen and (min-width:769px) and (max-width:1024px) {
    .divBg {
        height: 625px !important;
        min-height: 625px !important;
    }

    .modal.left .modal-dialog {
        right: 10px !important;
        margin-top: 12% !important;
    }

    .arrowIcon {
        position: absolute !important;
        top: 720px !important;
        bottom: 0px !important;
    }
}

@media screen and (min-width:1025px) and (max-width:1200px) {
    .divBg {
        height: 475px !important;
        min-height: 475px !important;
    }

    .modal.left .modal-dialog {
        right: 10px !important;
        margin-top: 7% !important;
    }

    .arrowIcon {
        position: absolute !important;
        top: 650px !important;
        bottom: 0px !important;
    }
}

@media screen and (min-width:1200px) and (max-width:1447px) {
    .divBg {
        height: 625px !important;
        min-height: 625px !important;
    }

    .modal.left .modal-dialog {
        right: 10px !important;
        margin-top: 5% !important;
    }

    .arrowIcon {
        position: absolute !important;
        top: 730px !important;
        bottom: 0px !important;
    }
}

@media screen and (min-width: 1900px) and (max-width:2560px) {
    .divBg {
        height: 700px !important;
        min-height: 700px !important;
    }

    .modal.left .modal-dialog {
        right: 10px !important;
        margin-top: 5% !important;
    }

    .arrowIcon {
        position: absolute !important;
        top: 810px !important;
        bottom: 0px !important;
    }
}

.divBg p {
    font-size: 14px;
}

.divBg label {
    font-size: 14px;
}

.reloadIcon {
    font-size: 20px;
    padding: 3px;
}

/* .formDiv {
    padding-top: 25px !important;
} */

/* submit button */
.billCart {
    background-color: #ff953e !important;
    color: #000000 !important;
}

.iconDiv{
    padding: 10px;
    border: 2px solid var(--bgcolor);
    border-radius: 50px;
}

.carIcon {
    /* height: 50px;
    width: 50px; */
    font-size: 24px;
    margin-right: 10px;
    color: var(--bgcolor);
    margin-right: 7px !important;
}

.site-footer {
    content: "" !important;
    position: absolute !important;
    width: 100%;
    background-color: var(--bgcolor);
    font-size: 18px;
}

.site-footer p {
    color: white !important;
}

.site-footer a {
    color: white !important;
    text-decoration: none;
}

/* input with icon */
.inner-addon {
    position: relative;
}

.inner-addon .glyphicon {
    position: absolute;
    padding: 10px;
    pointer-events: none;
}

.right-addon .glyphicon {
    right: 0px;
    z-index: 1;
}

.right-addon input {
    padding-right: 30px;
}

/* scroll bar */
.divBg::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.divBg::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
}

.divBg::-webkit-scrollbar-thumb {
    background-color: #ff8901;
    /* border: 2px solid #ff8901; */
}

/* Radio Switch */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 24px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #ff8901;
}

input:focus+.slider {
    box-shadow: 0 0 1px #ff8901;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}


/* time css change */
.rdtCounters>div {
    color: #000000 !important;
}

.rdtCounters {
    display: block !important;
}

.rdtPicker {
    min-width: 0 !important;
}


/* Ariport */
.buttons {
    background-color: white;
    width: fit-content;
    margin-top: 9px;
    padding: 5px;
    border-radius: 5px;
}

.buttons>span {
    color: var(--bgcolor);
}

.buttons>p {
    color: var(--bgcolor);
}

.buttons {
    padding: 0px;
}

.number_padd {
    padding-left: 10px;
    padding-right: 10px;
}

@media screen and (min-width: 401px) and (max-width: 490px) {

    .buttons {
        padding: 0px;
    }

}

@media screen and (min-width: 768px) and (max-width: 900px) {
    .number_padd {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }

    .buttons {
        padding: 0px;
    }
}

.airport-decrement-btn {
    cursor: pointer;
    padding-left: 10px;
}

.airport-increment-btn {
    cursor: pointer;
}

.no_of {
    font-size: 16px;
}

/* model */
.modal.left .modal-dialog {
    position: fixed;
    right: 25px;
    margin: auto;
    margin-top: 4%;
    /* width: 320px; */
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content {
    overflow-y: auto;
}

/* cart */
.cartBtn {
    background-color: var(--bgcolor) !important;
    color: #fff !important;
    font-weight: bold !important;
}

.modal {
    color: var(--bgcolor) !important;
}

.modelHeight {
    overflow-y: scroll;
    min-height: 250px;
    height: 250px;
}

.modelHeight::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.modelHeight::-webkit-scrollbar {
    width: 2px;
    background-color: #F5F5F5;
}

.modelHeight::-webkit-scrollbar-thumb {
    background-color: #b9555d;
    /* border: 2px solid #b73c00; */
}

.txtColor {
    color: var(--bgcolor) !important;
}

#DecP {
    margin: 0 !important;
}

.ReturnDiv {
    opacity: 0.5;
}

.removeStop {
    cursor: pointer;
}

.arrowIcon {
    position: absolute;
    top: 850px;
    bottom: 0px;
}

.modal-footer {
    display: block !important;
}

.emptyCart {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
}

/* select 2 style */
.css-1nmdiq5-menu {
    color: black !important;
    z-index: 2 !important;
}

.css-t3ipsp-control {
    border-color: none !important;
    box-shadow: 0 0 0 1px #000000 !important;
}

.option-image {
    width: 8%;
    height: 8%;
    padding: 2%;
}

.option-vehimage {
    width: 5.5%;
    height: 5.5%;
    padding: 2%;
}

.flight-details {
    color: var(--bgcolor);
    background-color: white;
    border-radius: 10px;
    padding: 12px;
    font-size: 17px;
}

/* cart */

#lblCartCount {
    font-size: 10px;
    color: #fff;
    /* vertical-align: bottom;
    margin-left: -26.5px;
    margin-bottom: 10px; */
    font-size: 10px;
    margin-top: 13px;
    /* position: fixed; */
    border: 1px solid white;
    background-color: var(--bgcolor);
    margin-left: -18px;
    pointer-events: none;
}

.paySelect {
    border-radius: 15px;
    border: 1px solid var(--bgcolor);
    color: var(--bgcolor);
    background-color: #fff;
    padding: 3px;
}

/* card module css */
.model-top {
    display: block !important;
}

.close-btn {
    position: absolute !important;
    right: 0;
    top: -20px;
    opacity: 4;
    margin-right: 0px !important;
    background: #fff;
    border-radius: 30px;
    padding: 4px !important;
    height: 17px;
    color: black;
    border-color: white;
}

.model-submit {
    background: #ff8901 !important;
    color: black !important;
    margin-top: 20px !important;
    /* border: none !important; */
    padding: 3px 10px;
}

.submit-parent {
    text-align: center;
}

.cart-module-container {
    border-radius: 15px !important;
}

.cart-module-body {
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
}

.cart-module-heder {
    font-Size: "40px"
}

.cardDetails {
    padding: 10px;
}

/* verify model css */
.add-emp-btn {
    background-color: #ff8901;
    border: none;
    color: white;
    border-radius: 0.5em;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    margin: auto;
    display: block;
    text-align: center;
}

.emp-add-input input:focus {
    border-color: none;
    box-shadow: none;
}

.add-modal {
    background-color: var(--bgcolor);
    color: white;
}

.add-modal i {
    cursor: pointer;
    font-size: 20px;
}

/* fare model */
.confirnBook {
    background-color: #ff8901 !important;
    border-radius: 20px;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */
    /* padding-bottom: 40px; */
}

.confirnClose i {
    font-size: 23px;
    margin-top: 15px;
    margin-right: 15px;
    border-radius: 1em;
    cursor: pointer;
    color: #000;
}

.confirm-title {
    text-decoration: underline;
    font-size: 16px !important;
    font-weight: bold;
    line-height: 10px;
    letter-spacing: 2px;
    color: #000;
}

.pick-circle {
    width: 12px;
    height: 12px;
    margin-top: 6px;
    background-color: green;
    border-radius: 1em;
}

.from-locat {
    color: #000;
    font-weight: bold;
    margin-left: 10px;
    margin-bottom: 5px;
}

.from-addr {
    color: rgb(52, 50, 50);
    padding-left: 20px;
}

.drop-circle {
    width: 12px;
    height: 12px;
    background-color: red;
    margin-top: 6px;
    border-radius: 1em;

}

.to-locat {
    margin-left: 10px;
    color: #000;
    font-weight: bold;
    margin-bottom: 5px;
}

.to-addr {
    color: rgb(52, 50, 50);
    padding-left: 20px;
}

.fare-head {
    background-color: #b73c00;
    font-size: 20px;
    color: #ecd4cd;
    font-weight: 600;
}

.checkout {
    background-color: #d3571b;
}

.dist-fare {
    border-right: 1px solid #b73c00;
}

.dist-fare-head {
    color: rgb(33, 32, 32);
    font-size: 14px;
    font-weight: 500;
}

.dist-charge {
    font-size: 16px;
    font-weight: bold;
    color: #000;
}

.dist-charge>span {
    font-size: 14px;
    font-weight: 500;
    color: rgb(33, 32, 32);
}

.dist-time {
    font-size: 14px;
    font-weight: 500;
    color: rgb(33, 32, 32);
}

.fare-head {
    background-color: #b73c00;
    font-size: 20px;
    color: #ecd4cd;
    font-weight: 600;
}

.fare-hr {
    opacity: 1 !important;
    margin: 0 !important;
}

.fare-list1 span {
    color: black;
    font-size: 35px;
    font-weight: bolder;
}

.fare-list1 {
    margin-top: -10px !important;
    color: rgb(41, 40, 40);
    font-size: 17px;
    font-weight: 700;
    letter-spacing: 1px;
}

.fare-list span {
    color: rgb(41, 40, 40);
    font-size: 15px;
}

.fare-total {
    color: black;
    font-weight: bolder;
    font-size: 16px;
    letter-spacing: 2px;
}

.fare-total span {
    color: #000;
    font-size: 18px;
}

@media only screen and (max-width: 767px) {

    .splitCol{
        margin-bottom: 8px;
    }

    .dist-fare-head {
        font-size: 12px;
    }

    .dist-charge {
        font-size: 14px;
    }

    .fare-list {
        font-size: 15px;
    }

    .fare-list1 span {
        font-size: 16px;
    }

    .fare-list1 {
        margin-top: 0 !important;
        font-size: 15px;
    }

    .fare-total {
        font-size: 16px;
    }
}

#fare-table {
    width: 100%;
    table-layout: fixed;
}

#fare-scroll {
    height: 110px;
    min-height: 50%;
    overflow-y: scroll;
    overflow-x: hidden;
    /* margin-bottom: 20px; */
}

#fare-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

#fare-scroll::-webkit-scrollbar {
    width: 1px;
    background-color: #F5F5F5;
}

#fare-scroll::-webkit-scrollbar-thumb {
    background-color: #000000;
    border: 2px solid #b73c00;
}

#fare-table td {
    color: rgb(33, 32, 32);
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 1px;
}
#fare-category{
    font-size: 16px;
}
#ma-fare-table{
    width:100%;
    table-layout: fixed;
}
.geo-body{
    background-color: var(--bgcolor) !important;
}
.modal-header{
    border-bottom: 0px !important
}
.modal-header{
    border-bottom: 0px !important
}
.modal-footer{
    border-top:  0px !important;
}
.verifyLabel{
    color: #F5F5F5;
}
.VerBtn{
    background-color: #ff8901 !important;
    color: #000 !important;
    font-weight: bold !important;
}
.farecon{
    background-color: var(--bgcolor);
}
.labelgeo{
    font-weight: bold;
    color: #000;
    font-size: 18px !important;
}


/* new design */
.new_div{
    display: flex;
    justify-content: space-between;
}

.new-cart{
    text-align: right !important;
}

.divBg-new {
    background-color: var(--bgcolor);
    color: #fff;
    padding: 25px !important;
    border-radius: 10px;
}

@media (max-width: 425px){
    .new_div{
        display: flex !important;
        flex-wrap: wrap;
    }

    .new-cart{
        text-align: left !important;
        order: 1;
    }

    .new-container{
        order: 2;
    }   
}

.react-datepicker-wrapper{
    display: block !important;
}

.accordion-body{
    padding: 0 !important;
}

.hourbutton{
    background-color: white;
    width: fit-content;
    padding: 5px;
    border-radius: 5px;
}

.alertText{
    color: var(--bgcolor);
}

.megHeader{
    border-bottom: 1px solid black !important;
}

.vehopacity{
    opacity: 0.5;
}

@media screen and (min-width:1024px) {
    .marginDiv{
        margin-top: -14px !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 3000px) {
    #lblCartCount{
        position: fixed;
    }
}

/* addon */
.addon-modal-body{
    background-color: #fff;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 20px;
}
.AddonTextBG{
    background-color: #ff953e;
    color: #000;
    text-align: center;
}
.AddonDiv{
    height: 500px;
    min-height: 500px;
    overflow-y: scroll;
    padding: 5px;
}
.Addon-image{
    width: 75%;
}
.addon-list{
    border: 2px solid #ca2733 !important;
    padding: 5px;
    text-align: center;
}
.addon-p{
    margin-bottom: 0px !important;
    margin-top: 16px !important;
}
.addon-edit{
    background-color: #ff8901;
    color: #000;
    padding: 5px;
    border-radius: 10px;
    font-size: 14px;
    cursor: pointer;
}
.addon-buttons{
    border: 2px solid #ca2733 ;
    width: fit-content;
    margin-top: 9px;
    padding: 5px;
    border-radius: 5px;
}
.addon-info{
    padding-left: 0 !important;
    padding-top: 4px !important;
    color: #ff8901;
}

/* scroll bar */
.AddonDiv::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.AddonDiv::-webkit-scrollbar {
    width: 2px;
    background-color: #ff8901;
}

.AddonDiv::-webkit-scrollbar-thumb {
    background-color: #ff8901;
    border: 2px solid #000;
}